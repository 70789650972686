@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

.cropperInfoDiv {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: space-between;
}

.cropperInfoDiv .wrapper {
  flex: auto;
}

.cropperInfoDiv .cropperInfoButtonDiv {
  margin-left: 11px;
}

.question {

  margin-top: 20px;
  padding: 15px;
}

.questionOptionHidden {
  padding: 2px;
  display: none;
}

.bucketOption {
  font-weight: 600;
  font-size: smaller;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  white-space: nowrap;
  border-radius: 10px;
}

.bucketOptionSmall {
  min-width: 50px;
  margin: 5px;
  padding-left: 8px;
  padding-right: 8px;
  color: white;
  white-space: nowrap;
  border-radius: 15px;
}

.bucketOptionContainer {

  overflow-wrap: anywhere;
  min-height: 50px;

}

.answerTextMessage {
  margin-top: 20px;
}

.bucketOption0 {


  background-color: green;
}

.bucketOption1 {


  background-color: blue;
}

.bucketOption2 {

  background-color: blueviolet;
}

.bucketOption3 {

  background-color: rgb(68, 42, 7);
}

.bucketOption4 {

  background-color: darkgoldenrod;
}

.bucketOption5 {

  background-color: darkred;
}

.bucketItem {

  font-weight: 600;

}

.bucketItemAnswer {

  color: darkgreen;
}

.bucketItem {

  padding: 5px;
}

.bucketItemList {
  margin-left: 25px;
  display: inline-grid;
}





.variableSelect {
  padding: 5px;
  margin-right: 5px;
}



.variableSelectHidden {
  display: none;
}

.questionHeaderContainer {
  justify-content: right;
}

.variableSelectOption {
  margin-top: 5px;
}

.MuiChip-clickable:hover {

  background-color: #1976d2 !important;

}





.shimmer {
  color: grey;

  -webkit-mask: linear-gradient(-90deg, #000 30%, #0005, #000 70%) right/300% 100%;
  mask: linear-gradient(-90deg, #000 30%, #0005, #000 70%) right/300% 100%;

  animation: shimmer 1.5s infinite;

}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left
  }
}